<template>
  <div>
    <div class="row items-center q-py-sm q-px-md">
      <div class="col row items-center justify-end">
        <portal-target
          v-show="hasQueue"
          name="assembling-queues"
          class="q-ml-sm"
          @change="handleQueueChange"
        />

        <q-btn
          :color="fastMode ? 'positive' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="$t('Fast Mode') + ' ' + (fastMode ? $t('ON') : $t('OFF'))"
          no-caps
          @click="handleFastModeChange"
        />

        <q-btn
          color="warning"
          text-color="dark"
          size="sm"
          :label="$t('Reset')"
          no-caps
          @click="handleReset"
        />

        <portal-target
          v-show="hasQueue"
          name="assembling-buttons"
          class="q-ml-sm"
          @change="handleQueueChange"
        />
      </div>
    </div>

    <portal-target name="assembling-body" />

    <div class="border rounded q-ma-sm">
      <div class="text-dark q-px-sm row items-stretch">
        <div
          class="col text-h6 border-right row items-center q-px-xs"
          style="min-height: 100%;"
        >
          <div>
            {{ $t('Check out workstation') + ': ' }}
          </div>

          <div v-if="place" class="q-ml-sm">
            {{ place }}
          </div>

          <div
            v-else
            class="col text-center text-white bg-amber rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
          >
            {{ $t('Please scan the order ID to check out the order!') }}
          </div>
        </div>

        <div
          class="col q-px-xs row items-center"
          style="min-height: 72px;"
        >
          <div class="col">
            <barcode-input
              v-if="!isLoading && !isHiddenField"
              :settings="{catchAll: false}"
              :out-focused="isFocused"
              :has-max-width="false"
              :disabled="isLoading"
              @barcode="handleBarcode"
            />
          </div>
        </div>
      </div>

      <div
        v-show="hasOrder"
        class="row items-center border-top q-pa-sm"
      >
        <portal-target
          name="assembling-dr"
          @change="handleDRChange"
        />

        <div v-if="!hasDR" class="text-h6 text-bold">
          {{ $t('No shipment') }}
        </div>

        <q-space />

        <portal-target name="assembling-items" />
      </div>

      <div
        v-show="hasOrder"
        class="row items-center border-top q-pa-sm"
      >
        <div class="col-4">
          <portal-target name="assembling-delivery-service" />
        </div>

        <div class="col-4 q-pt-xs">
          <portal-target name="assembling-shop" />
        </div>

        <div class="col-4">
          <portal-target
            name="assembling-order"
            @change="handleOrderChange"
          />
        </div>
      </div>
    </div>





    <div v-if="order" class="" >
      <div
        v-if="order"
        class="row q-pa-sm border-top border-bottom"
      >
        <div class="col-12 row border-bottom q-pb-sm">
          <div class="col-10 text-h6 text-weight-bold">
            Order: {{ order.id + '/' + $moment(order.date).format((appOptions.formats.date)) }}
          </div>
        </div>

        <div class="col-sm-4">

          <address class="q-mt-sm" >
            <div v-if="order._embedded.deliveryRequest">
              <strong>
                {{ order._embedded.deliveryRequest._embedded.deliveryService.name }} [{{ order._embedded.deliveryRequest._embedded.deliveryService.id }}]
              </strong>
              
              <span >
                {{ $t('Delivery Request') + ': ' +  order._embedded.deliveryRequest.id }}
              </span>
  
              <br>
              
              <span >
                {{ $t('Delivery Request') + ': ' +  order._embedded.deliveryRequest.id }}
              </span>
            </div>
            <br>

            # {{ order.extId }}

            <br>

            

            <br>


            <span v-if="order._embedded.phone">{{ $t('Phone') + ': ' + order._embedded.phone.phone }}</span>
          </address>
        </div>

      </div>  



      <!-- <q-table
            row-key="id"
            class="full-width table--only-header"
            :rows-per-page-label="$t('Rows per page')"
            :rows="[order]"
            :columns="columns"
            flat
          /> -->

        <q-btn
        color="blue-6"
        text-color="white"
        class="q-ml-sm col-12 q-mt-md"
        :label="$t('Check out')"
        :disable="disabled"
        no-caps
        unelevated
        @click="OrderToCompleted"
      />
         
    </div>
   

    <printer-settings-modal
      ref="printerSettingsModal"
      @close="handlePrinterSettingsClose"
    />

   
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations,mapActions } from 'vuex'

// Components
import BarcodeInput from '../../components/barcode-input/BarcodeInput'
import PrinterSettingsModal from '../../components/modals/PrinterSettingsModal.vue'
import NewPickingPlace from '../../components/picking/new-picking-place.vue'

export default {
  name: 'CheckOut',
  components: {
    BarcodeInput,
    PrinterSettingsModal,
    NewPickingPlace
  },
  data () {
    return {
      hasDR: false,
      isFocused: false,
      action: 'scanBasket',
      queues: [],
      barcode: null,
      order: null,
      isLoading: false,
      isHiddenField: false,
      fastMode: false,
      hasOrder: false,
      hasQueue: false,
      columns: [
        {
          name: 'selected',
          label: `${this.$t('Mark')} (${this.$t('optional')})`,
          align: 'center',
          classes: 'border-right',
          headerClasses: 'border-right'
        },
        {
          name: 'image',
          label: this.$t('Image'),
          align: 'left'
        },
        {
          name: 'id',
          label: this.$t('Id'),
          align: 'left',
          field: 'id'
        },
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left',
          field: 'name'
        },
        {
          name: 'payment',
          label: this.$t('Payment'),
          align: 'left',
          field:'payment'
        },
        {
          name: 'estimatedCost',
          label: this.$t('Estimated Cost'),
          align: 'left',
          field:'estimatedCost'
        },
        {
          name: 'count',
          label: this.$t('Count'),
          align: 'left',
          field: 'count'
        },
        {
          name: 'total',
          label: this.$t('Total'),
          align: 'left',
          field: 'total'
        },
        {
          name: 'actions',
          label: '',
          align: 'right',
          field: 'actions'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'printer'
    ]),
  },
  mounted () {
    this.$service.printer.turnOnReconnect()

  },
  unmounted () {
    this.$service.printer.turnOffReconnect()

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapActions([
      'loadStoragePlaces',
    ]),
    ...mapMutations([
      'setPrinter',
      'addErrorNotification'
    ]),
    OrderToCompleted() {
      const state = 'complete'
       this.$service.order.save({ state }, this.order.id, this.order.type)
              
    },
    handleDRChange (hasDR) {
      this.hasDR = hasDR
    },
    handleQueueChange (hasQueue) {
      this.hasQueue = hasQueue
    },
    handleOrderChange (hasOrder) {
      this.hasOrder = hasOrder
    },
    handleFastModeChange () {
      this.fastMode = !this.fastMode
    },
    handleFastMode (isOn) {
      this.fastMode = !!isOn
    },
    handlePrinterSettingsClose () {
      this.isFocused = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.barcode = null
      this.place = null
      this.showBarcodeField()
    },
    openPrinterSettings () {
      this.isFocused = true
      this.$refs.printerSettingsModal.open()
    },
    handleStartLoading () {
      this.isLoading = true
    },
    handleStopLoading () {
      this.isLoading = false
    },
    handleBarcode (barcode) {
        if (barcode == '') {
          this.addErrorNotification('Please, scan order id.')
          return
        }
          return this.$service.order.get(barcode.value).then((data) =>{
            this.order = data
        })
        
    },
    showBarcodeField () {
      this.isHiddenField = false
    },
    handleBlock () {
      this.isFocused = true
    },
    handleUnblock () {
      this.isFocused = false
    }
  }
}
</script>
